body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  background-color: #f6f6f6 !important;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
}

.mb5 {margin-bottom: 5px}
.mb10 {margin-bottom: 10px}
.mb15 {margin-bottom: 15px}
.mb20 {margin-bottom: 20px}
.mt10 {margin-top: 10px}
.mt20 {margin-top: 20px}
.mr10 {margin-right: 10px}
.mr15 {margin-right: 15px}
.mr20 {margin-right: 20px}
.ml10 {margin-left: 10px}
.ml15 {margin-left: 15px}
.r {float: right}
.tr {text-align: right}
.tl {text-align: left}
.tc {text-align: center}
.c0 {color: #000000}
.ovh {overflow: hidden}
.pl0 {padding-left: 0 !important;}
.pl10 {padding-left: 10px}
.pl15 {padding-left: 15px}
.pr15 {padding-right: 15px}
.pr0 {padding-right: 0 !important;}
.b {font-weight: bold;}
.f14 {font-size: 14px}
.f16 {font-size: 16px !important;}
.g6 {color: #666666}
.n {font-weight: normal}
.poi { cursor: pointer }
